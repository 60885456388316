import { ColorSchemasEnum } from '@constants/constants';
import { useTheme } from '@mui/system';

type ColorMapType = {
  bg: string;
  fg: string;
};

const useColors = () => {
  const theme = useTheme();

  const mapColorByName = (color: string | null): string => {
    const colorsMap: Record<string, { color: string }> = {
      red: { color: theme.palette.wRed },
      green: { color: theme.palette.wGreen },
      orange: { color: theme.palette.wOrange },
      yellow: { color: theme.palette.wYellow },
      blue: { color: theme.palette.wBlue },
      black: { color: theme.palette.wBlack },
      white: { color: theme.palette.wWhite },
      default: { color: theme.palette.wBlue },
      gray3: { color: theme.palette.gray3 },
    };

    if (color === null || color.includes('#')) {
      return colorsMap.default.color;
    }

    return colorsMap[color]?.color || colorsMap.default.color;
  };

  const getColorBasedOnStyle = (colorSchema: ColorSchemasEnum, currentColor: string = null): ColorMapType => {
    const mapping: Record<string, { [key: string]: ColorMapType }> = {
      [ColorSchemasEnum.DARK_ON_LIGHT]: {
        red: {
          fg: theme.palette.wRed,
          bg: theme.palette.wWhite,
        },
        deepRed: {
          fg: theme.palette.wDeepRed,
          bg: theme.palette.wWhite,
        },
        green: {
          fg: theme.palette.wGreen,
          bg: theme.palette.wWhite,
        },
        orange: {
          fg: theme.palette.wOrange,
          bg: theme.palette.wWhite,
        },
        yellow: {
          fg: theme.palette.wYellow,
          bg: theme.palette.wWhite,
        },
        yellowGreen: {
          fg: theme.palette.wYellowGreen,
          bg: theme.palette.wWhite,
        },
        blue: {
          fg: theme.palette.wBlue,
          bg: theme.palette.wWhite,
        },
        black: {
          fg: theme.palette.wBlack,
          bg: theme.palette.wWhite,
        },
        white: {
          fg: theme.palette.wWhite,
          bg: theme.palette.wWhite,
        },
        transparent: {
          fg: theme.palette.wTransparent,
          bg: theme.palette.wWhite,
        },
        default: {
          bg: theme.palette.wWhite,
          fg: theme.palette.wBlue,
        },
      },
      [ColorSchemasEnum.LIGHT_ON_DARK]: {
        red: {
          bg: theme.palette.wRed,
          fg: theme.palette.wWhite,
        },
        deepRed: {
          bg: theme.palette.wDeepRed,
          fg: theme.palette.wWhite,
        },
        green: {
          bg: theme.palette.wGreen,
          fg: theme.palette.wWhite,
        },
        orange: {
          bg: theme.palette.wOrange,
          fg: theme.palette.wWhite,
        },
        yellow: {
          bg: theme.palette.wYellow,
          fg: theme.palette.wWhite,
        },
        yellowGreen: {
          bg: theme.palette.wYellowGreen,
          fg: theme.palette.wWhite,
        },
        blue: {
          bg: theme.palette.wBlue,
          fg: theme.palette.wWhite,
        },
        black: {
          bg: theme.palette.wBlack,
          fg: theme.palette.wWhite,
        },
        gray4: {
          bg: theme.palette.gray4,
          fg: theme.palette.wWhite,
        },
        white: {
          bg: theme.palette.wWhite,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        default: {
          bg: theme.palette.wBlue,
          fg: theme.palette.wWhite,
        },
      },
      [ColorSchemasEnum.DARK_ON_TRANSPARENT]: {
        red: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wRed,
        },
        deepRed: {
          bg: theme.palette.wWhite,
          fg: theme.palette.wRed,
        },
        green: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wGreen,
        },
        orange: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wOrange,
        },
        yellow: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellow,
        },
        yellowGreen: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellowGreen,
        },
        blue: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
        black: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlack,
        },
        white: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wTransparent,
        },
        default: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
      },
      [ColorSchemasEnum.LIGHT_ON_TRANSPARENT]: {
        red: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wRed,
        },
        deepRed: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wDeepRed,
        },
        green: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wGreen,
        },
        orange: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wOrange,
        },
        yellow: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellow,
        },
        yellowGreen: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellowGreen,
        },
        blue: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
        black: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlack,
        },
        white: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wTransparent,
        },
        default: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
      },
    };

    if (colorSchema && currentColor) {
      return mapping[colorSchema][currentColor];
    } else if (colorSchema && !currentColor) {
      return mapping[colorSchema].default;
    } else {
      return {
        bg: theme.palette.wWhite,
        fg: theme.palette.wBlue,
      };
    }
  };

  const getFieldStyleBasedOnColorSchema = ({
    colorSchema,
    field,
  }: {
    colorSchema: ColorSchemasEnum;
    field: 'customSelect' | 'customInput' | 'jsonEditor' | 'button';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Record<string, any> => {
    const lightColor = theme.palette.mode === 'light' ? theme.palette.wWhite : theme.palette.wDefault;
    const darkColor = theme.palette.primary.main;

    if (field === 'customInput') {
      switch (colorSchema) {
        case ColorSchemasEnum.LIGHT_ON_TRANSPARENT:
        case ColorSchemasEnum.LIGHT_ON_DARK:
          return {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: lightColor,
              },
              '&:hover fieldset': {
                borderColor: lightColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: lightColor,
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: lightColor,
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: lightColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: lightColor,
            },
            '& .MuiInputBase-input': {
              color: lightColor,
            },
            '& .MuiInputAdornment-root .MuiIconButton-root': {
              color: lightColor,
            },
            '& .MuiInputLabel-root': {
              color: lightColor,
              '&.Mui-focused': {
                color: lightColor,
              },
            },
          };
        case ColorSchemasEnum.DARK_ON_LIGHT:
        case ColorSchemasEnum.DARK_ON_TRANSPARENT:
          return {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: darkColor,
              },
              '&:hover fieldset': {
                borderColor: darkColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: darkColor,
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: darkColor,
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: darkColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: darkColor,
            },
            '& .MuiInputBase-input': {
              color: darkColor,
            },
            '& .MuiInputAdornment-root .MuiIconButton-root': {
              color: darkColor,
            },
            '& .MuiInputLabel-root': {
              color: darkColor,
              '&.Mui-focused': {
                color: darkColor,
              },
            },
          };
        default:
          return null;
      }
    } else if (field === 'button') {
      switch (colorSchema) {
        case ColorSchemasEnum.LIGHT_ON_TRANSPARENT:
        case ColorSchemasEnum.LIGHT_ON_DARK:
          return {
            borderColor: lightColor,
            color: lightColor,
            ':hover': {
              borderColor: lightColor,
            },
          };
        case ColorSchemasEnum.DARK_ON_LIGHT:
        case ColorSchemasEnum.DARK_ON_TRANSPARENT:
          return {
            borderColor: darkColor,
            color: darkColor,
            ':hover': {
              borderColor: darkColor,
            },
          };
        default:
          return null;
      }
    } else if (field === 'customSelect') {
      switch (colorSchema) {
        case ColorSchemasEnum.LIGHT_ON_TRANSPARENT:
        case ColorSchemasEnum.LIGHT_ON_DARK:
          return {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: lightColor,
              },
              '&:hover fieldset': {
                borderColor: lightColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: lightColor,
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: lightColor,
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: lightColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: lightColor,
            },
            '& .MuiInputBase-input': {
              color: lightColor,
            },
            '& .MuiInputAdornment-root .MuiIconButton-root': {
              color: lightColor,
            },
            '& .MuiInputLabel-root': {
              color: lightColor,
              '&.Mui-focused': {
                color: lightColor,
              },
            },
            '& .MuiSvgIcon-root': {
              color: lightColor,
            },
          };
        case ColorSchemasEnum.DARK_ON_LIGHT:
        case ColorSchemasEnum.DARK_ON_TRANSPARENT:
          return {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: darkColor,
              },
              '&:hover fieldset': {
                borderColor: darkColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: darkColor,
              },
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: darkColor,
            },
            '& .MuiInput-underline:hover:before': {
              borderBottomColor: darkColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: darkColor,
            },
            '& .MuiInputBase-input': {
              color: darkColor,
            },
            '& .MuiInputAdornment-root .MuiIconButton-root': {
              color: darkColor,
            },
            '& .MuiInputLabel-root': {
              color: darkColor,
              '&.Mui-focused': {
                color: darkColor,
              },
            },
          };
        default:
          return null;
      }
    } else if (field === 'jsonEditor') {
      switch (colorSchema) {
        case ColorSchemasEnum.LIGHT_ON_TRANSPARENT:
        case ColorSchemasEnum.LIGHT_ON_DARK:
          return {
            labelColorSchema: lightColor,
            borderColorSchema: lightColor,
            activeColorSchema: lightColor,
            errorColorSchema: theme.palette.wRed,
          };
        case ColorSchemasEnum.DARK_ON_LIGHT:
        case ColorSchemasEnum.DARK_ON_TRANSPARENT:
          return {
            labelColorSchema: darkColor,
            borderColorSchema: darkColor,
            activeColorSchema: darkColor,
            errorColorSchema: theme.palette.wRed,
          };
        default:
          return null;
      }
    }
    return null;
  };

  return {
    getColorBasedOnStyle,
    mapColorByName,
    getFieldStyleBasedOnColorSchema,
  };
};

export default useColors;
