import { highlightSelectedStyle } from '@constants/constants';
import { WidgetProps } from '@modules/reports/components/WidgetWrap';
import { Box } from '@mui/material';
import useMedia from '@utils/useMedia';
import { useSelector } from 'react-redux';
import WidgetEditControls from '../../WidgetEditControls';
import st from './style.module.css';

// eslint-disable-next-line @typescript-eslint/naming-convention
const W_Line = (props: WidgetProps) => {
  const { objectProperties, selected, ...widgetEditControlsProps } = props;

  const getPropValue = (prop: string): string | number | null =>
    objectProperties.find((obj) => obj.key === prop)?.value;

  const { getImageById } = useMedia();
  const color = getPropValue('settingsColor') as string | undefined;
  const leftEnd = getPropValue('settingsLeftEnd') as string | undefined;
  const rightEnd = getPropValue('settingsRightEnd') as string | undefined;
  const orientation = getPropValue('settingsOrientation') as string | undefined;
  const lineWidth = getPropValue('settingsLineWidth') as number | undefined;
  const imageUid = getPropValue('settingsCoverImage') as string | undefined;

  const isEditMode = useSelector((state: any) => state.settings.isEditMode);

  const lineStyle = {
    width: `${
      orientation === 'vertically'
        ? lineWidth === 1
          ? '1px'
          : lineWidth === 2
          ? '2px'
          : lineWidth === 4
          ? '4px'
          : '6px'
        : 'calc(100% - 10px)'
    }`,
    height: `${
      orientation === 'horizontally'
        ? lineWidth === 1
          ? '1px'
          : lineWidth === 2
          ? '2px'
          : lineWidth === 4
          ? '4px'
          : '6px'
        : 'calc(100% - 10px)'
    }`,
    top: `${orientation === 'horizontally' && '50%'}`,
    left: `${orientation === 'vertically' && '50%'}`,
    backgroundColor: color || 'transparent',
  };

  return (
    <div
      className={st.wrapper}
      style={{
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
      }}
    >
      <Box
        className={st.arrowWrapper}
        sx={{
          ...lineStyle,
          '&:before': {
            display: rightEnd === 'arrow' ? 'block' : 'none',
            transform: `${
              orientation === 'horizontally' ? 'rotate(45deg) translate(0, -50%)' : 'rotate(-45deg) translate(0, -50%)'
            }`,
            top: `${
              orientation === 'horizontally'
                ? lineWidth === 1 || lineWidth === 4
                  ? '-2px'
                  : '-1px'
                : lineWidth === 1
                ? '7px'
                : lineWidth === 2
                ? '6px'
                : lineWidth === 4
                ? '11px'
                : '10px'
            }`,
            right: `${orientation === 'horizontally' && (lineWidth === 1 || lineWidth === 2 ? '7px' : '11px')}`,
            left: `${orientation === 'vertically' && '-1px'}`,
            width: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            height: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            borderTop: `${lineWidth || 1}px solid ${color || 'transparent'}`,
            borderRight: `${lineWidth || 1}px solid ${color || 'transparent'}`,
          },
          '&:after': {
            display: leftEnd === 'arrow' ? 'block' : 'none',
            transform: `${
              orientation === 'horizontally' ? 'rotate(225deg) translate(0, 50%)' : 'rotate(135deg) translate(0, 50%)'
            }`,
            top: `${orientation === 'horizontally' && (lineWidth === 1 || lineWidth === 4 ? '-2px' : '-1px')}`,
            bottom: `${
              orientation === 'vertically' &&
              (lineWidth === 1 ? '-3px' : lineWidth === 4 ? '-6px' : lineWidth === 2 ? '-3px' : '-7px')
            }`,
            left: `${
              orientation === 'horizontally' ? (lineWidth === 1 ? '-3px' : lineWidth === 2 ? '-5px' : '-6px') : '-1px'
            }`,
            width: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            height: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            borderTop: `${lineWidth || 1}px solid ${color || 'transparent'}`,
            borderRight: `${lineWidth || 1}px solid ${color || 'transparent'}`,
          },
        }}
      />
      {imageUid && <img className={st.backgroundImage} src={getImageById(imageUid)} alt="icon" />}

      <WidgetEditControls {...widgetEditControlsProps} />
    </div>
  );
};

export default W_Line;
