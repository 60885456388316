import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import WidgetEditControls from '@components/WidgetEditControls';
import { highlightSelectedStyle } from '@constants/constants';
import { RPC_PARAMS_QUERY } from '@graphql/queries';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import useColors from '@utils/useColors';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import FormField from '@components/FormField';
import RpcButton from '@components/widgets/W_AdvancedButton/RpcButton';
import { UPDATE_WIDGET } from '@modules/widgets/api/UpdateWidget';
import { BASE_HEIGHT } from '@utils/widgetSizes';

const GET_DATA_SUBSCRIPTION = gql`
  subscription Objects($objId: UUID!) {
    Objects(filterA: { id: [$objId] }) {
      event
      relatedNode {
        ... on ObjectProperty {
          id
          linkedPropertyId
          groupName
          property
          value
          key
        }
      }
      relatedNodeId
    }
  }
`;

const W_AdvancedButton = (props) => {
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);
  const [editWidget] = useMutation(UPDATE_WIDGET);

  const { id, objectProperties, selected } = props;
  const [loadRpcParamsQuery, { data: rpcParams }] = useLazyQuery(RPC_PARAMS_QUERY, {});

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const action = getPropValue('valueValue');
  const buttonText = getPropValue('valueText');
  const params = getPropValue('valueParams');

  let defaultValues = {};

  const [valuesParams, setValuesParams] = useReducer(
    (prev, updated) => (updated['action'] === 'reset' ? defaultValues : { ...prev, ...updated }),
    defaultValues
  );

  const handleInputParamsChange = (e) => {
    let { name, value } = e.target;
    setValuesParams({ [name]: value });
  };

  const loadRpcParams = async (rpc) => {
    setValuesParams({ ['action']: 'reset' });
    const localAction = getPropValue('valueValue');
    if (rpc?.schemaId || action?.schemaId) {
      try {
        await loadRpcParamsQuery({
          variables: {
            schemaId: rpc?.schemaId || localAction.schemaId,
            rpc: rpc?.value || localAction.value,
          },
          fetchPolicy: 'cache-first',
        });
      } catch (err) {
        toast.error(err.toString());
      }
    }
  };
  const client = useApolloClient();

  const colorSchema = getPropValue('settingsStyle');

  const { getColorBasedOnStyle } = useColors();

  const valueCurrentColor = getPropValue('valueCurrentColor');
  const isFieldEmpty = getPropValue('valueValue')?.value;
  const [colors, setColors] = useState(getColorBasedOnStyle(colorSchema, valueCurrentColor));

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const handleExecuteControl = () => {
    return isFieldEmpty
      ? createExecution({
          variables: {
            input: {
              controlExecution: {
                objectId: action.objectId,
                params: valuesParams,
                name: action.value,
              },
            },
          },
        }).then((e) => {
          editWidget({
            variables: {
              widgetId: id,
              values: [
                {
                  propertyKey: 'valueParams',
                  value: valuesParams,
                },
              ],
            },
          });

          return e;
        })
      : toast.error('No control was found.');
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        loadRpcParams(data.Objects.relatedNode.value).then(() => {});
      } else if (data.Objects.relatedNode?.key === 'valueCurrentColor') {
        setColors(getColorBasedOnStyle(colorSchema, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === 'settingsStyle') {
        setColors(getColorBasedOnStyle(data.Objects.relatedNode?.value, valueCurrentColor));
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  useEffect(() => {
    loadRpcParams().then(() => {});
  }, [id]);

  const mergeValues = (defaults, userValues) => {
    return { ...defaults, ...userValues };
  };

  return (
    <div
      className={'force-scroll'}
      style={{
        position: 'relative',
        backgroundColor: colors.bg,
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
        padding: '4px 8px',
        height: '100%',
      }}
    >
      <div
        style={{
          height: '100%',
          padding: colorSchema !== 'lightontransparent' ? '0 2px' : '0',
          gridColumnGap: '6px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gridTemplateRows: `repeat(auto-fill, ${BASE_HEIGHT}px)`,
          gridAutoFlow: 'dense',
        }}
      >
        {rpcParams?.schemaControls?.map((item) => {
          const formField = {
            type: {
              name: item.typeSpec,
            },
            key: item.argument,
            defaultValue: item?.defaultValue,
            valueRange: item?.valueRange,
            valueSet: item?.valueSet,
          };

          return (
            <div
              style={{
                minHeight: '50px',
                display: 'flex',
                alignItems: 'center',
                gridRow: item.typeSpec.includes('json') && 'auto / span 2',
                height: item.typeSpec.includes('json') && '100px',
                padding: item.typeSpec.includes('json') && '5px 0',
              }}
            >
              <FormField
                isSwitchable={false}
                key={item.argument}
                name={item.argument}
                field={formField}
                variant={'outlined'}
                style={item.typeSpec.includes('json') ? { height: '100%' } : null}
                size={'small'}
                colorSchema={colorSchema}
                label={item.description + (item.units ? `, ${item.units}` : '')}
                value={mergeValues(params, valuesParams)[item.argument] ?? ''}
                onChange={handleInputParamsChange}
              />
            </div>
          );
        })}
        <RpcButton disabled={!rpcParams} colorSchema={colorSchema} action={action} onClick={handleExecuteControl}>
          {buttonText || 'n/a'}
        </RpcButton>
      </div>

      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_AdvancedButton;
